import React from 'react';
import { useRenderCount } from '@honeycomb/data';
import Head from 'next/head';
import { PageContent } from '../features/content/PageContent';
export function CmsContentPage({ getPage: pageContent }) {
    var _a;
    useRenderCount('CmsContentPage');
    const noIndex = pageContent.noIndex ? 'noindex' : undefined;
    const noFollow = pageContent.noFollow ? 'nofollow' : undefined;
    const robotsContent = [noIndex, noFollow].filter(Boolean).join(',');
    const robotsMeta = robotsContent && React.createElement("meta", { name: "robots", content: robotsContent });
    return (React.createElement(React.Fragment, null,
        React.createElement(Head, null, robotsMeta),
        React.createElement(PageContent, { components: (_a = pageContent.components) !== null && _a !== void 0 ? _a : [] })));
}
