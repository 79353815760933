var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { OverlayImagePosition } from '@honeycomb/data';
import { Image } from '@honeycomb/ui-core';
import * as React from 'react';
export function HeroOverlayImage(props) {
    const { src2x, linkUrl, openInNewTab = false, position = OverlayImagePosition.Top } = props, other = __rest(props, ["src2x", "linkUrl", "openInNewTab", "position"]);
    const buttonProps = linkUrl
        ? {
            button: true,
            href: linkUrl,
            target: openInNewTab ? '_new' : undefined,
        }
        : {};
    return (React.createElement(Image, Object.assign({ srcset: src2x ? `${src2x} 2x` : undefined, alignSelf: position === OverlayImagePosition.Bottom ? 'flex-end' : 'flex-start', flexShrink: 0, display: { xs: 'none', l: 'block' }, disableLazy: true, ImageElementProps: {
            fetchPriority: 'high',
        } }, buttonProps, other)));
}
