import React from 'react';
import dynamic from 'next/dynamic';
import { LazyLoadComponent } from '@honeycomb/ui-core';
import { AccordionGroup } from './AccordionGroup';
import { Carousel } from './Carousel/Carousel';
import { SectionHeading } from './SectionHeading';
import { InlineLinkGroup } from './InlineLinkGroup';
import { IntroText } from './IntroText';
import { Headline } from './Headline';
import { ContentBlock } from './ContentBlock';
import { EmailSignupForm } from './EmailSignupForm';
import { Alignment as VideoAlignment, LandingPageVideo } from './LandingPageVideo';
const TabsetComponent = dynamic(() => import('./Tabset').then((module) => module.Tabset), {
    ssr: false,
});
const Faq = dynamic(() => import('./Faq').then((module) => module.Faq), {
    ssr: false,
});
const PaymentMethod = dynamic(() => import('./PaymentMethod').then((module) => module.PaymentMethod), { ssr: false });
const KeyFacts = dynamic(() => import('./KeyFacts').then((module) => module.KeyFacts), {
    ssr: false,
});
const Weather = dynamic(() => import('./Weather/Weather').then((module) => module.Weather), {
    ssr: false,
});
const LinkMenu = dynamic(() => import('./LinkMenu/LinkMenu').then((module) => module.LinkMenu), {
    ssr: false,
});
const FlightRoutes = dynamic(() => import('./FlightRoutes').then((module) => module.FlightRoutes), {
    ssr: false,
});
export function GenericSubComponent(props) {
    const { component, alias, tabIndex, moreLinkText, zoneIds } = props;
    // eslint-disable-next-line no-underscore-dangle
    switch (component.__typename) {
        case 'IntroText':
            return React.createElement(IntroText, { headingText: component.sectionHeader, bodyText: component.introText });
        case 'SectionHeading':
            return React.createElement(SectionHeading, { alias: alias, sectionHeading: component });
        case 'Carousel': {
            return React.createElement(Carousel, { carousel: component, tabIndex: tabIndex, moreLinkText: moreLinkText, zoneIds: zoneIds });
        }
        case 'VideoWidget': {
            return (React.createElement(LandingPageVideo, { thumbnailImageUrl: component.thumbnailImageUrl || '', subHeading: component.subHeading || undefined, subHeadingDescription: component.subHeadingDescription || undefined, button: component.button || undefined, videoAlignment: component.videoAlignment === 'Right' ? VideoAlignment.right : VideoAlignment.left, videoLink: component.videoLink, playInPopUp: component.playInPopUp }));
        }
        case 'Faq': {
            return (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(Faq, { sectionHeader: component.sectionHeader, faqAccordionGroup: component.faqAccordionGroup }) }));
        }
        case 'Headline': {
            return (React.createElement(Headline, { header: component.header, details: component === null || component === void 0 ? void 0 : component.details, __typename: component.__typename }));
        }
        case 'Tabset': {
            return (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(TabsetComponent, { tabset: component }) }));
        }
        case 'AccordionGroup': {
            return React.createElement(AccordionGroup, { accordionGroup: component });
        }
        case 'PaymentCard': {
            return (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(PaymentMethod, { paymentMethod: component }) }));
        }
        case 'KeyFacts': {
            return (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(KeyFacts, { keyFacts: component }) }));
        }
        case 'ContentBlock': {
            return React.createElement(ContentBlock, { contentBlock: component });
        }
        case 'WeatherItemGroup': {
            return (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(Weather, { weatherData: component }) }));
        }
        case 'LinkMenu': {
            return (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(LinkMenu, { linkMenu: component }) }));
        }
        case 'InlineLinkGroup': {
            return React.createElement(InlineLinkGroup, { linkGroup: component });
        }
        case 'FlightRoute': {
            return (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(FlightRoutes, { routeData: component }) }));
        }
        case 'SignupForm': {
            return React.createElement(EmailSignupForm, { component: component });
        }
        default:
            // eslint-disable-next-line no-underscore-dangle
            return React.createElement("div", null, component.__typename);
    }
}
